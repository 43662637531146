import { graphql, StaticQuery } from "gatsby";
import React from "react";
import GoogleAds from "./googleAds";
import Ads from "./ads";
const RightNav = ({ records }) => (
  <StaticQuery
    query={graphql`
      query {
        allStrapiPost {
          edges {
            node {
              title
              category {
                name
              }
            }
          }
        }
        allFeedGatsbyBlog {
          edges {
            node {
              title
              link
              content
              contentSnippet
            }
          }
        }
        feedGatsbyBlog {
          title
          link
          content
          contentSnippet
        }
      }
    `}
    render={(data) => (
      <div className="right_side_main_container">
        {/* right_side_main_div */}
        <div className=" right_side_main_div ">
          {/* ads_boxes_main_div */}
          <div className="ads_boxes_main_div">
            {/* common_box_Ads_main_dv  */}
            {/* {data?.allFeedGatsbyBlog?.edges?.slice(5, 9).map((single) => ( */}
            <div className="common_box_Ads_main_dv ">
              <GoogleAds />
              {/* <Ads /> */}
            </div>

            {/* ))} */}
          </div>
          {/* flex_div */}
          <div className="flex_div">
            {/* card_div */}
            <div className="card_div ">
              {/* heading */}

              {/* content_main_div */}
              <div className="ads_boxes_main_div">
                {/* common_box_Ads_main_dv  */}
                {data?.allFeedGatsbyBlog?.edges?.slice(15, 20).map((single) => (
                  <a href={single?.node?.link} target="_blank" className="">
                    <div
                      className="common_box_Ads_main_dv2"
                      style={{
                        backgroundImage:
                          "url(" +
                          single?.node?.contentSnippet?.split("\n")[0] +
                          ")",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        display: "flex",
                        alignItems: "flex-end",
                        width: "",
                      }}
                    >
                      <div className="common_transparent_color ">
                        <p>{single?.node?.title}</p>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  />
);
export default RightNav;
