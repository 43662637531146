import React from 'react'
import AdSense from 'react-adsense';
function GoogleAds() {
    return (
        <>
            <div className="common_box_Ads_main_dv">
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8319377204356997"
                    crossorigin="anonymous"></script>
                <AdSense.Google
                    client='ca-pub-8319377204356997'
                    slot='5656423267'
                    style={{ display: 'block' }}
                    format='auto'
                    responsive='true'
                // layoutKey='-gw-1+2a-9x+5c'
                />
            </div>
        </>
    )
}

export default GoogleAds