import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
const PageContent = () => (
  <StaticQuery
    query={graphql`
      query {
        allFeedGatsbyBlog {
          edges {
            node {
              title
              link
              contentSnippet
            }
          }
        }
        feedGatsbyBlog {
          title
          link
          content
          contentSnippet
        }
      }
    `}
    render={(data) => (
      <div className="page_main_div">
        {/* Rss_div */}
        {console.log("link", data)}
        <div className="Rss_div">
          {data?.allFeedGatsbyBlog?.edges?.slice(0, 5).map((single) => (
            <a href={single?.node?.link} target="_blank" className="top_link_container m_l10 m_r10">
              <div className="rss_thumbnail_common_box"
                style={{
                  backgroundImage: "url(" + single?.node?.contentSnippet?.split("\n")[0] + ")",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  display: "flex",
                  alignItems: "flex-end",
                  width: "100%",
                }}>
                <div className="common_transparent_color w_100p">
                  <p>{single?.node?.title}</p>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div >
    )}
  />
)
export default PageContent;